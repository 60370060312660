/*eslint-disable*/
import React, { Component } from "react";
// @material-ui/core components
import List from "@material-ui/core/List";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Router from "next/router";
import styled from "styled-components";
import { withTheme } from "styled-components";
import { maxDevice, device } from "../../src/utils/breakpoints";
import { isTablet, browserName,isMobile,isBrowser } from "react-device-detect";
import * as selectors from "../../src/selectors";
import * as searchActions from "../../src/actions/search";
import { connect } from "dva";
import _ from "lodash";
import * as profileSelectors from "../../src/selectors/profile";
import { Color } from "../../src/utils/Color";
import Constants from "../../src/constants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Hidden from "@material-ui/core/Hidden";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ListItemContainer = styled.li`
  color: inherit;
  position: relative;
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  float: left;

  @media  (max-width: 1279px){
    float: none;
    color: white;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    margin-top: 5px;
  }
  & .MuiMenu-list {
    padding: 0;
  }
`;

const ListTabletItemContainer = styled.div`
  color: inherit;
  position: relative;
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  float: none;
`;

const TabletButtonContainer = styled.div`
  position: relative;
  font-weight: 600;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  color: white;
  font-size: 20px;
  padding: 1.5rem 0.6rem;

  &:hover,
  &:focus {
    color: white;
    background: rgba(200, 200, 200, 0.2);
  }
`;

const ButtonContainer = styled.a`
  color: ${props => (props.isActive ? Color.primaryColor : "inherit")};
  position: relative;
  padding: 0.6rem 1em;
  // font-weight: 600;
  font-weight: ${props => (props.isActive ? "600" : "600")};
  font-size: 1em;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  text-decoration: none;
  margin: 0;
  display: inline-flex;
  justify-content: space-between;

  &:hover,
  &:focus {
    color: inherit;
    background: rgba(200, 200, 200, 0.2);
  }

  @media (max-width: 1279px) {
    text-decoration: ${props => (props.isActive ? "underline" : "none")};
    text-underline-offset: ${props => (props.isActive ? "10px" : "none")};
    color: white;
    font-size: 20px;
    font-weight: 600;
    padding: 1.5rem 0.6rem;
    &:hover,
    &:focus {
      color: white;
      background: rgba(200, 200, 200, 0.2);
    }
  }
`;

const DesktopHidden = styled.div`
 display: block;

  @media (max-width: 1279px){
    display: none;
  }
`;
const MobileHidden = styled.div`
 display: none;

  @media (max-width: 1279px) {
    display: block;
  }
`;


class HeaderLeftLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownAnchor: null,
      dropDown: null
    };
  }
  onClickProject = e => {
    e.preventDefault();

    Router.push("/project-listing");
  };

  onClickEvent = e => {
    e.preventDefault();

    Router.push("/news-and-events");
  };

  onClickRent = (e, page) => {
    const { clearFilterData } = this.props;
    e.preventDefault();
    clearFilterData && clearFilterData();

    Router.push({
      pathname: "/property-for-rent"
    });
  };

  onClickContactUs = (e, page) => {
    e.preventDefault();

    Router.push({
      pathname: "/contact-us"
    });
  };

  onClickSale = (e, page) => {
    const { clearFilterData } = this.props;

    e.preventDefault();
    clearFilterData && clearFilterData();
    Router.push({
      pathname: "/property-for-sale"
    });
  };

  onClickAgent = e => {
    e.preventDefault();
    const { classes, meData } = this.props;
    const isAgent = !_.isEmpty(profileSelectors.getProfileAgent(meData));

    Router.push({
      pathname: isAgent ? "/agent" : "/agent-sign-in"
    });
  };

  onClickLoanCalculator = e => {
    e.preventDefault();

    // this.setState({
    //   dropdownAnchor: e.target
    // });
    Router.push("/home-loan-calculator");
  };

  routeToLoan = e => {
    e.preventDefault();
    this.handleClose();
    Router.push("/home-loan-calculator");
  };

  routeToCareer = e => {
    e.preventDefault();
    this.handleClose();
    Router.push("/career");
  };

  routeToTransactionAndStatistics = e => {
    e.preventDefault();
    this.handleClose();
    Router.push("/transaction-and-statistics");
  };

  handleClose = () => {
    this.setState({
      dropdownAnchor: null
    });
  };

  onClickComingSoon = e => {
    e.preventDefault();
    this.handleClose();
    Router.push({
      pathname: "/coming-soon"
    });
  };

  onClickDropDownEvent = (event, menu) => {
    event.preventDefault();
    const menuItem = _.get(this.state, ["dropDown", menu], false);

    this.setState({
      dropDown: { [menu]: !menuItem }
    });
  };

  onClickGuide = e => {
    e.preventDefault();
    Router.push({
      pathname: "/insider-guide"
    });
  };

  onClickCareer = e => {
    e.preventDefault();
    Router.push({
      pathname: "/career"
    });
  };

  onClickLookingForAgent = e => {
    e.preventDefault();
    Router.push({
      pathname: "/looking-for-agent"
    });
  };

  onClickLookingForDeveloper = e => {
    e.preventDefault();
    Router.push({
      pathname: "/looking-for-developer"
    });
  };

  onClickWhyGenie = e => {
    e.preventDefault();
    Router.push({
      pathname: "https://agents.propertygenie.com.my"
    });
  };

  onClickDirectory = e => {
    //e.preventDefault();
  };
  render() {
    const { dropdownAnchor, dropDown } = this.state;
    const { classes, meData } = this.props;
    const isAgent = !_.isEmpty(profileSelectors.getProfileAgent(meData));

    const headerData = [
      {
        title: "Buy",
        event: e => this.onClickSale(e),
        router: "/property-for-sale"
      },
      {
        title: "Rent",
        event: e => this.onClickRent(e),
        router: "/property-for-rent"
      },
      {
        title: "Projects",
        event: e => this.onClickProject(e),
        router: "/project-listing"
      },
      {
        title: "Guides",
        event: e => this.onClickGuide(e),
        router: "/insider-guide"
      },
      {
        // title: "Calculator",
        // event: e => this.routeToLoan(e),
        // router: "/home-loan-calculator"
        title: "Transactions",
        event: e => this.routeToTransactionAndStatistics(e),
        router: "/transaction-and-statistics"
      },
      {
        title: "Others",
        event: e => this.onClickDirectory(e),
        // router: "/",
        dropdownEvent: e => this.onClickDropDownEvent(e, "more"),
        type: Constants.DROPDOWN_MENU,
        dropdownKey: "more",
        dropdown: [
          {
            title: "Agent Directory",
            event: e => this.onClickLookingForAgent(e),
            router: "/looking-for-agent"
          },
          {
            title: "Developer Directory",
            event: e => this.onClickLookingForDeveloper(e),
            router: "/looking-for-developer"
          },
          {
            // title: "Transaction and Statistics",
            // event: e => this.routeToTransactionAndStatistics(e),
            // router: "/transaction-and-statistics"
            title: "Calculator",
            event: e => this.routeToLoan(e),
            router: "/home-loan-calculator"
          },
          {
            title: "Career",
            event: e => this.routeToCareer(e),
            router: "/career"
          }
        ]
      },
      {
        title: "Why Genie?",
        event: e => this.onClickWhyGenie(e),
        router: "https://agents.propertygenie.com.my"
      },
      {
        title: "Contact Us",
        event: e => this.onClickContactUs(e),
        router: "/contact-us"
      }
    ];

    return (
        <List
            style={{
              fontFamily: '"Poppins", sans-serif',
              fontWeight: "300",
              lineHeight: "1.5em",
              fontSize: "14px",
              paddingLeft: "0",
              listStyle: "none",
              paddingTop: "0",
              paddingBottom: "0",
              color: "inherit"
            }}
        >
          {headerData.map((item, index) => {
            const title = _.get(item, ["title"], "");
            const event = _.get(item, ["event"], "");
            const dropdownEvent = _.get(item, ["dropdownEvent"], "");
            const router = _.get(item, ["router"], "");
            const type = _.get(item, ["type"], "");
            const dropdownMenu = _.get(item, ["dropdown"], []);
            const dropdownKey = _.get(item, ["dropdownKey"], []);

            return  (
                <ListItemContainer className="dropdown" key={index}>
                  <DesktopHidden>
                    <ButtonContainer
                        onClick={
                          !_.isEqual(type, Constants.DROPDOWN_MENU)
                              ? event
                              : e => {
                                e.preventDefault();
                              }
                        }
                        className="dropbtn"
                        href={router}
                        color={`${Color.transparent}`}
                        // target="_blank"
                        isActive={_.isEqual(Router.asPath, router)}
                    >
                      {title}
                      {_.isEqual(type, Constants.DROPDOWN_MENU) ? (
                          <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
                      ) : (
                          false
                      )}
                    </ButtonContainer>
                    {_.isEqual(type, Constants.DROPDOWN_MENU) && !_.isEmpty(dropdownMenu) ? (
                        <div className="dropdown-content">
                          {!_.isEmpty(dropdownMenu)
                              ? _.map(dropdownMenu, (item, index) => {
                                const title = _.get(item, ["title"], "");
                                const event = _.get(item, ["event"], "");
                                const router = _.get(item, ["router"], "");
                                return (
                                    <MenuItem style={{ padding: 0, minHeight: "auto" }}>
                                      <ButtonContainer
                                          style={{
                                            padding: "1em 1.2em",
                                            fontSize: "0.9em",
                                            width: "100%",
                                            borderTop: index !== 0 ? `1px solid ${Color.borderColor}` : ""
                                          }}
                                          href={router}
                                          onClick={event}
                                      >
                                        {title}
                                      </ButtonContainer>
                                    </MenuItem>
                                );
                              })
                              : false}
                        </div>
                    ) : (
                        false
                    )}
                  </DesktopHidden>

                  <MobileHidden>
                    <ButtonContainer
                        onClick={!_.isEqual(type, Constants.DROPDOWN_MENU) ? event : dropdownEvent}
                        href={router}
                        style={{ display: "flex", alignItems: "center" }}
                        color="transparent"
                        // target="_blank"
                        isActive={_.isEqual(Router.asPath, router)}
                    >
                      <div>{title}</div>
                      {_.isEqual(type, Constants.DROPDOWN_MENU) ? (
                          <KeyboardArrowDownIcon style={{ fontSize: 25, position: 'absolute', right: 0 }} />
                      ) : (
                          false
                      )}
                    </ButtonContainer>

                    {_.isEqual(type, Constants.DROPDOWN_MENU) &&
                    !_.isEmpty(dropdownMenu) &&
                    _.get(dropDown, [dropdownKey], false)
                        ? !_.isEmpty(dropdownMenu)
                            ? _.map(dropdownMenu, (item, index) => {
                              const title = _.get(item, ["title"], "");
                              const event = _.get(item, ["event"], "");
                              const router = _.get(item, ["router"], "");
                              return (
                                  <MenuItem className="fade-slide-down" style={{ padding: 0, minHeight: "auto" }}>
                                    <ButtonContainer
                                        style={{
                                          padding: "1em 1.2em",
                                          width: "100%"
                                        }}
                                        href={router}
                                        onClick={event}
                                    >
                                      {title}
                                    </ButtonContainer>
                                  </MenuItem>
                              );
                            })
                            : false
                        : false}
                  </MobileHidden>
                </ListItemContainer>
            );
          })}
        </List>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state),
    meData: selectors.getProfileData(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFilterData: () => dispatch(searchActions.clearFilterData())
  };
}

export default withTheme(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderLeftLinks)
);

